import { Loading } from '@collection-platform-frontend/ui';
import { AuthAction, withAuthUser } from 'next-firebase-auth';

const IndexPage = () => {
  return <div />;
};

export default withAuthUser({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
  whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
  whenUnauthedAfterInit: AuthAction.REDIRECT_TO_LOGIN,
  LoaderComponent: Loading,
})(IndexPage);
